const CenterDecorator = (props: { children: React.ReactNode }) => (
	// Appreciate this isn't text align center but visually it's the same
	<span
		// eslint-disable-next-line react-native/no-inline-styles
		style={{
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
			textAlign: 'center',
		}}
	>
		{props.children}
	</span>
);

export default CenterDecorator;
